import React, { Component } from 'react'
import './App.css';
import Person from './person.js';



export default class App extends Component {


  state = {
    currentPerson: 0,
    people: [
      {
        title: "Greta, IT specialiste",
        imageUrl: "img/greta.png",
        description: "Nuostabius, nors ir nevisada veikiančius, skrydžių puslapius.",
        backgroundColor: "#f2e95e"
      },
      {
        title: "Dovile, IT specialiste",
        imageUrl: "img/dovile.png",
        description: "Nuostabius, nors ir nevisada veikiančius, skrydžių puslapius."
      }
    ]
  }
  showNextPerson() {
    this.setState({
      currentPerson: this.state.currentPerson + 1
    })
  }

  linkejimai() {
    console.log("linkejimai");
  }
  render() {
    const i = this.state.currentPerson;
    return (


      <div>
        <section id="homepage">

          <div>
            <div className="logo-box">
              <img src="img/logo.svg" className="logo" alt="logo" />
            </div>

            <div className="grupe text-center">
              <img src="img/grupe.png" className="grupe-img" alt="grupe" />
            </div>

          </div>

        </section>



        <Person
          title={this.state.people[i].title}
          imageUrl={this.state.people[i].imageUrl}
          description={this.state.people[i].description}
          backgroundColor={this.state.people[i].backgroundColor}
        >

        </Person>


        <section id="juokemes">
          <div className="title-container">
            <h1>Juokėmės susiemę už pilvų, nes...</h1>
            <h3>Mes nesijuokiame - mes rimtai dirbame. O jūs ne?</h3>
          </div>

          <div className="bottom-content"
            style={{
              backgroundImage: "url(" + "img/explosion2.png" + ")"
            }}
          >
            <div className="gif-box"
              style={{
                backgroundImage: "url(" + "https://media1.tenor.com/images/a50e887ed9c13b6eb5fca8bf3b8c95d4/tenor.gif?itemid=9271317" + ")"
              }}>
            </div>
          </div>

        </section>



        <section id="juokemes" style={{ backgroundColor: '#17b25b' }}>
          <img src="img/bottom.png" alt="Bang" />
          <div className="title-container">
            <h1>Šiais metais turėjome daug...</h1>
            <h3>
              ..problemų, gedimų ir nevilties.
            </h3>
          </div>

          <div className="bottom-content"
            style={{
              backgroundImage: "url(" + "img/explosion2.png" + ")"
            }}
          >
            <div className="gif-box"
              style={{
                backgroundImage: "url(" + "https://i.gifer.com/JdeC.gif" + ")"
              }}>
            </div>

          </div>
        </section>


        <section id="juokemes" style={{ backgroundColor: '#cd5372' }}>
          <img src="img/bottom-green2.png" alt="Bang" />
          <div className="title-container">
            <h1>Liejome ašaras dėl...</h1>
            <h3>- neįsikeliančių boarding’ų <br /> - per greit atėjusių deadline’ų <br /> - neveikiančių sistemų</h3>
          </div>

          <div className="bottom-content"
            style={{
              backgroundImage: "url(" + "img/explosion2.png" + ")"
            }}
          >
            <div className="gif-box"
              style={{
                backgroundImage: "url(" + "https://media1.tenor.com/images/4527e65719f534fca051167bd3411156/tenor.gif?itemid=14693670" + ")"
              }}>
            </div>

          </div>
          <img src="img/bottom-green.png" alt="Bang" className="rotate180" />
        </section>



        <section id="juokemes" style={{ backgroundColor: '#53cd7a' }}>

          <div className="title-container">
            <h1>2020 metais norime tapti...</h1>
            <h3>Kompanija, kur visi naudoja Gmail, o ne Outlook!</h3>
          </div>

          <div className="bottom-content"
            style={{
              backgroundImage: "url(" + "img/explosion2.png" + ")"
            }}
          >
            <div className="gif-box"
              style={{
                backgroundImage: "url(" + "https://media3.giphy.com/media/aKdVEgNzBP8hG/source.gif" + ")"
              }}>
            </div>

          </div>
          <img src="img/bottom.png" alt="Bang" className="rotate180" />
        </section>


        <section id="juokemes" style={{ backgroundColor: '#1665e3' }}>

          <div className="finalSlide">
            <img src="img/final.svg" className="final" alt="" />
          </div>


        </section>



      </div >


    );
  }
}