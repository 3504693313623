import React, { Component } from 'react'

export default class person extends Component {
    state = {
        currentPerson: 0,
        people: [
            {
                title: "Greta, programerių užkalbėtoja",
                imageUrl: "img/greta.png",
                description: "Atsakinga už nuostabius, nors ir ne visada veikiančius, skrydžių puslapius",
                backgroundColor: "#f2e95e"
            },
            {
                title: "Dovilė, sistemos gaisrų gesintoja",
                imageUrl: "img/dovile.png",
                description: "Atsakinga už tarpgalaktinę komunikaciją su programuotojais ir kitomis planetomis.",
                backgroundColor: "#c3ffdd"
            },
            {
                title: "Austėja, mygtukų inžinierė",
                imageUrl: "img/austeja.png",
                description: "Atsakinga už sklandesnį ir patogesnį rytojų mūsų brangiems vartotojams",
                backgroundColor: "#a5daff"
            },
            {
                title: "Tautvydas, Outlook specialistas",
                imageUrl: "img/tautvydas2.png",
                description: "Atsakingas už nespausdinančius printerius, iššokusias lenteles ir truputį už kelionių projektus",
                backgroundColor: "#ffe0dd"
            }
        ]
    }

    showNextPerson() {

        if (this.state.currentPerson >= 0 && this.state.currentPerson < 3) {
            this.setState({
                currentPerson: this.state.currentPerson + 1
            })
            console.log(this.state.currentPerson);
        }
        else if (this.state.currentPerson > 5) {
            this.setState({
                currentPerson: 0
            })
            console.log(this.state.currentPerson);
        }

    }
    onSwipeLeft() {
        console.log("swiped");
    }
    showPrevPerson() {
        if (this.state.currentPerson > 0) {
            this.setState({
                currentPerson: this.state.currentPerson - 1
            })
        }
        else {
            this.setState({
                currentPerson: 0
            })
        }


    }
    render() {

        return (

            <section id="one-person-container" style={{ backgroundColor: this.state.people[this.state.currentPerson].backgroundColor }}>
                <div className="bg-container">
                    <h1>{this.state.people[this.state.currentPerson].title}</h1>
                </div>
                <img src="img/bottom.png" alt="Bang" />
                <div className="person-box text-center">
                    <img src={this.state.people[this.state.currentPerson].imageUrl} className="person" alt="" />
                    <h1>{this.state.people[this.state.currentPerson].description}</h1>
                </div>
                <div className="arrow">
                    {this.state.currentPerson > 0 ?
                        <span className="prev" onClick={this.showPrevPerson.bind(this)}> <img src="img/arrow.svg" className="arrow-prev" alt="" /> </span>
                        : ""}
                    {this.state.currentPerson < 3 ?
                        <span className="next" onClick={this.showNextPerson.bind(this)}> <img src="img/arrow.svg" className="arrow-next" alt="" /></span>
                        : ""}
                </div>
                <img src="img/bottom.png" alt="Bang" className="rotate180" />
            </section >

        )
    }
}
